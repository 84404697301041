import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "body_wrap about_page cmnBody_wrap view_profile_page"
}
const _hoisted_2 = { class: "view_profile" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "view_profile_inner" }
const _hoisted_5 = { class: "my_topics_lists text-center" }
const _hoisted_6 = { class: "user_avtar" }
const _hoisted_7 = {
  key: 0,
  class: "user_avtar_image"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "user_avtar_image"
}
const _hoisted_10 = { class: "avtar_name" }
const _hoisted_11 = { class: "user_name_head" }
const _hoisted_12 = { class: "user_profile_info" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { class: "user_profile_social" }
const _hoisted_17 = {
  key: 0,
  class: "user_profile_link"
}
const _hoisted_18 = ["href"]
const _hoisted_19 = {
  key: 1,
  class: "user_profile_link"
}
const _hoisted_20 = ["href"]
const _hoisted_21 = {
  key: 2,
  class: "user_profile_link"
}
const _hoisted_22 = ["href"]
const _hoisted_23 = {
  key: 3,
  class: "user_profile_link"
}
const _hoisted_24 = ["href"]
const _hoisted_25 = {
  key: 0,
  class: "user_profile_box"
}
const _hoisted_26 = { class: "spoken_languages" }
const _hoisted_27 = {
  key: 1,
  class: "user_profile_box"
}
const _hoisted_28 = { class: "topick_list" }
const _hoisted_29 = {
  key: 2,
  class: "user_profile_box"
}
const _hoisted_30 = { class: "user_profile_box_info" }
const _hoisted_31 = {
  key: 3,
  class: "user_profile_box"
}
const _hoisted_32 = { class: "user_profile_box_info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_section = _resolveComponent("header-section")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_header_section, {
            title: _ctx.user.username
          }, null, 8, ["title"]),
          _createElementVNode("section", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (_ctx.user.image)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createElementVNode("img", {
                            src: _ctx.user.image,
                            alt: ""
                          }, null, 8, _hoisted_8)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("p", null, _toDisplayString(_ctx.initials), 1)
                          ])
                        ])),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("h2", null, _toDisplayString(_ctx.user.username), 1),
                      _createElementVNode("ul", _hoisted_12, [
                        _createElementVNode("li", null, [
                          _cache[0] || (_cache[0] = _createElementVNode("span", null, "Name :", -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.user.firstname) + " " + _toDisplayString(_ctx.user.lastname), 1)
                        ]),
                        (_ctx.user.profession)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                              _cache[1] || (_cache[1] = _createElementVNode("span", null, "Job :", -1)),
                              _createTextVNode(" " + _toDisplayString(_ctx.user.profession), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.user.language)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                              _cache[2] || (_cache[2] = _createElementVNode("span", null, "Language :", -1)),
                              _createTextVNode(" " + _toDisplayString(_ctx.getLanguageLabel(_ctx.user.language)), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.user.country)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_15, _toDisplayString(_ctx.getCountryLabel(_ctx.user.country)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("ul", null, [
                          (_ctx.user.facebook_url)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_17, [
                                _createElementVNode("a", {
                                  target: "_blank",
                                  href: _ctx.user.facebook_url
                                }, _cache[3] || (_cache[3] = [
                                  _createElementVNode("i", { class: "fa fa-facebook" }, null, -1)
                                ]), 8, _hoisted_18)
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.user.instagram_url)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_19, [
                                _createElementVNode("a", {
                                  target: "_blank",
                                  href: _ctx.user.instagram_url
                                }, _cache[4] || (_cache[4] = [
                                  _createElementVNode("i", { class: "fa fa-instagram" }, null, -1)
                                ]), 8, _hoisted_20)
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.user.youtube_url)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_21, [
                                _createElementVNode("a", {
                                  target: "_blank",
                                  href: _ctx.user.youtube_url
                                }, _cache[5] || (_cache[5] = [
                                  _createElementVNode("i", { class: "fa fa-youtube" }, null, -1)
                                ]), 8, _hoisted_22)
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.user.twitter_url)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_23, [
                                _createElementVNode("a", {
                                  target: "_blank",
                                  href: _ctx.user.twitter_url
                                }, _cache[6] || (_cache[6] = [
                                  _createElementVNode("i", { class: "fa fa-twitter" }, null, -1)
                                ]), 8, _hoisted_24)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      (_ctx.user.user_languages && _ctx.user.user_languages.length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                            _cache[7] || (_cache[7] = _createElementVNode("h5", { class: "user_designation" }, "Spoken Languages", -1)),
                            _createElementVNode("ul", _hoisted_26, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.user_languages, (language) => {
                                return (_openBlock(), _createElementBlock("li", {
                                  key: language.title
                                }, _toDisplayString(_ctx.getLanguageLabel(language.title)), 1))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.user.user_interests && _ctx.user.user_interests.length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            _cache[8] || (_cache[8] = _createElementVNode("h5", { class: "user_designation" }, "TopPick Interests", -1)),
                            _createElementVNode("ul", _hoisted_28, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.user_interests, (interest) => {
                                return (_openBlock(), _createElementBlock("li", {
                                  key: interest.interests.title
                                }, _toDisplayString(interest.interests.title), 1))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.user.bio)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                            _cache[9] || (_cache[9] = _createElementVNode("h5", { class: "user_designation" }, "Who I am", -1)),
                            _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.user.bio), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.user.interest_description)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                            _cache[10] || (_cache[10] = _createElementVNode("h5", { class: "user_designation" }, "What Are My Interests", -1)),
                            _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.user.interest_description), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_loader, { show: _ctx.isLoading }, null, 8, ["show"])
  ], 64))
}